
// Any group of form controls
.form-group {
  font-family: $font-family-sans-serif;
}

// Textual inputs & selects
.form-control {
  padding: 7px 6px 9px 16px;
  font-family: $font-family-sans-serif;
  background: $colour-default-grey-light;
  border: none;
  height:46px;
  caret-color: #f02;
}

.form-control:hover {
  background: darken($colour-default-grey-light, 5%);
}

.form-control:focus {
  background: $colour-default-white;
  box-shadow:0 2px 10px 0 rgba(0,0,0,.15);
}

.radio label,
.checkbox label {
  padding-left: 0;
}

textarea { resize: vertical; }

label {
  font-size: 16px;
  color: $colour-default-grey-medium-1;
  display: block;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;

  .radio & {
    cursor: pointer;
    font-size: $font-size-base;
    letter-spacing: inherit;
    padding: 0;
  }
  .checkbox & {
    cursor: pointer;
    font-size: $font-size-base;
    letter-spacing: inherit;
    padding: 0;
  }
  &.form-check-inline {
    cursor: pointer;
    font-size: $font-size-base;
    letter-spacing: inherit;
    padding: 0;
  }
  &.btn {
    letter-spacing: inherit;
  }

}

// Range
input[type=range].slider {
  -webkit-appearance: none;
  width: 100%;
  margin: 10px 0;
}
input[type=range].slider:focus {
  outline: none;
}
input[type=range].slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: $colour-default-grey-3;
  border-radius: 2.5px;
  border: 1px solid $colour-default-grey-6;
}
input[type=range].slider::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px $colour-default-grey-1, 0px 0px 0px #283742;
  border: 0px solid $colour-default-grey-1;
  height: 30px;
  width: 20px;
  border-radius: 2.5px;
  background: rgba(30, 42, 50, 0.93);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}
input[type=range].slider:focus::-webkit-slider-runnable-track {
  background: #f4f5f6;
}
input[type=range].slider::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: $colour-default-grey-3;
  border-radius: 2.5px;
  border: 1px solid $colour-default-grey-6;
}
input[type=range].slider::-moz-range-thumb {
  box-shadow: 0px 0px 0px $colour-default-grey-1, 0px 0px 0px #283742;
  border: 0px solid $colour-default-grey-1;
  height: 30px;
  width: 20px;
  border-radius: 2.5px;
  background: rgba(30, 42, 50, 0.93);
  cursor: pointer;
}
input[type=range].slider::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range].slider::-ms-fill-lower {
  background: #eaebec;
  border: 1px solid $colour-default-grey-6;
  border-radius: 6px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type=range].slider::-ms-fill-upper {
  background: $colour-default-grey-3;
  border: 1px solid $colour-default-grey-6;
  border-radius: 6px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type=range].slider::-ms-thumb {
  box-shadow: 0px 0px 0px $colour-default-grey-1, 0px 0px 0px #283742;
  border: 0px solid $colour-default-grey-1;
  height: 30px;
  width: 20px;
  border-radius: 2.5px;
  background: rgba(30, 42, 50, 0.93);
  cursor: pointer;
  height: 10px;
}
input[type=range].slider:focus::-ms-fill-lower {
  background: $colour-default-grey-3;
}
input[type=range].slider:focus::-ms-fill-upper {
  background: $colour-default-grey-10;
}


// custom radio & checkboxes
input[type=radio],
input[type=checkbox] { display:none; } /* to hide the checkbox itself */
input[type=radio] + label:before,
input[type=checkbox] + label:before {
  display: inline-block;
  width: 20px;
  font-family: FontAwesome;
  font-size: 110%;
}
input[type=checkbox] + label {
  margin-right: 15px;
}

input[type=radio] + label:before { content: "\f10c"; }
/* input[type=radio]:checked + label:before { content: "\f05d"; } */
input[type=radio]:checked + label:before { content: "\f192"; }
input[type=radio]:disabled + label:before { content: ''; }
input[type=radio]:disabled + label { text-decoration: line-through; }

input[type=checkbox] + label:before { content: "\f096";  }
input[type=checkbox]:checked + label:before { content: "\f046"; }

input[type=checkbox]:disabled + label:before { content: ''; }
input[type=checkbox]:disabled + label { text-decoration: line-through; }


// add some space around inline checkboxes
.form-check-inline {
  margin-left: .25em;
  margin-right: 1em;
}

// Validation error
.validation-error {
  & input {
    border-color: OrangeRed;
    color: OrangeRed;
  }

  & label {
    color: OrangeRed;
  }

  & .validation-error--message {
    color: OrangeRed !important;
  }
}