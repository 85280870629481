@font-face {
  font-family: 'Toyota-icon-13';
  src:  url('../fonts/Toyota-icon-13.eot?hfgl66');
  src:  url('../fonts/Toyota-icon-13.eot?hfgl66#iefix') format('embedded-opentype'),
    url('../fonts/Toyota-icon-13.ttf?hfgl66') format('truetype'),
    url('../fonts/Toyota-icon-13.woff?hfgl66') format('woff'),
    url('../fonts/Toyota-icon-13.svg?hfgl66#Toyota-icon-13') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Toyota-icon-restyle';
    src: url('../fonts/Toyota-icon-restyle.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Toyota-icon-restyle' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-android:before {
content: "\e900";}

.icon-angle-down:before {
content: "\e901";}

.icon-angle-left:before {
content: "\e902";}

.icon-angle-right:before {
content: "\e903";}

.icon-angle-up:before {
content: "\e904";}

.icon-apple:before {
content: "\e905";}

.icon-backwards:before {
content: "\e906";}

.icon-bluetooth:before {
content: "\e907";}

.icon-book:before {
content: "pending";}

.icon-bookmark:before {
content: "\e908";}

.icon-calculator:before {
content: "\e90a";}

.icon-calendar:before {
content: "\e90b";}

.icon-camera:before {
content: "\e90c";}

.icon-car:before {
content: "\e90d";}

.icon-car-rental:before {
content: "\e90f";}

.icon-car-used:before {
content: "\e910";}

.icon-checkbox-checked:before {
content: "\e911";}

.icon-checkbox-unchecked:before {
content: "\e912";}

.icon-chevron-down:before {
content: "\e913";}

.icon-chevron-left:before {
content: "\e914";}

.icon-chevron-right:before {
content: "\e915";}

.icon-chevron-up:before {
content: "\e916";}

.icon-cog:before {
content: "\e917";}

.icon-compare:before {
content: "\e918";}

.icon-consumptions:before {
content: "\e919";}

.icon-desktop:before {
content: "\e91a";}

.icon-download-alt:before {
content: "\e91b";}

.icon-email:before {
content: "\e91c";}

.icon-emission:before {
content: "\e91d";}

.icon-envelope-alt:before {
content: "\e91e";}

.icon-exclamation:before {
content: "\e91f";}

.icon-external-link:before {
content: "\e920";}

.icon-facebook:before {
content: "\e921";}

.icon-facetime-video:before {
content: "\e922";}

.icon-file-alt:before {
content: "\e923";}

.icon-file-pdf:before {
content: "\e924";}

.icon-find-your-match:before {
content: "\e927";}

.icon-flickr:before {
content: "\e928";}

.icon-folder-case:before {
content: "\e929";}

.icon-forward:before {
content: "\e92a";}

.icon-fuel-type:before {
content: "\e92b";}

.icon-google-plus:before {
content: "\e92c";}

.icon-heart:before {
content: "\e92d";}

.icon-hybrid:before {
content: "\e92e";}

.icon-info-sign:before {
content: "\e92f";}

.icon-insurance:before {
content: "\e931";}

.icon-ipod:before {
content: "\e932";}

.icon-laptop:before {
content: "\e934";}

.icon-link:before {
content: "\e935";}

.icon-list-ul:before {
content: "\e936";}

.icon-live-chat:before {
content: "\e937";}

.icon-locate:before {
content: "\e938";}

.icon-lock:before {
content: "\e939";}

.icon-map-marker:before {
content: "\e93b";}

.icon-menu:before {
content: "\e93c";}

.icon-minus:before {
content: "\e93d";}

.icon-mobile:before {
content: "\e93e";}

.icon-music:before {
content: "\e93f";}

.icon-new-2:before {
content: "\e940";}

.icon-newsletter:before {
content: "\e941";}

.icon-ok:before {
content: "\e942";}

.icon-pause:before {
content: "\e943";}

.icon-phone:before {
content: "\e944";}

.icon-picture:before {
content: "\e945";}

.icon-play:before {
content: "\e946";}

.icon-plug:before {
content: "\e947";}

.icon-plus:before {
content: "\e948";}

.icon-print:before {
content: "\e949";}

.icon-question-sign:before {
content: "\e94a";}

.icon-quote-left:before {
content: "\e94b";}

.icon-quote-right:before {
content: "\e94c";}

.icon-radio-checked:before {
content: "\e94d";}

.icon-radio-unchecked:before {
content: "\e94e";}

.icon-rain:before {
content: "\e94f";}

.icon-refresh:before {
content: "\e950";}

.icon-remove:before {
content: "\e951";}

.icon-reorder:before {
content: "\e952";}

.icon-repeat:before {
content: "\e953";}

.icon-reply:before {
content: "\e954";}

.icon-reset:before {
content: "\e955";}

.icon-resize-full:before {
content: "\e956";}

.icon-resize-small:before {
content: "\e957";}

.icon-rss:before {
content: "\e958";}

.icon-search:before {
content: "\e959";}

.icon-share:before {
content: "\e95b";}

.icon-skip-backward:before {
content: "\e95c";}

.icon-skip-forward:before {
content: "\e95d";}

.icon-snowflake:before {
content: "\e95e";}

.icon-star:before {
content: "\e95f";}

.icon-star-half:before {
content: "\e960";}

.icon-step-backward:before {
content: "\e961";}

.icon-step-forward:before {
content: "\e962";}

.icon-stop:before {
content: "\e963";}

.icon-sun-stroke:before {
content: "\e964";}

.icon-tablet:before {
content: "\e965";}

.icon-tag:before {
content: "\e966";}

.icon-test-drive:before {
content: "\e967";}

.icon-th-large:before {
content: "\e968";}

.icon-thumbs-up:before {
content: "\e969";}

.icon-trash:before {
content: "\e96a";}

.icon-tsm:before {
content: "\e96b";}

.icon-twitter:before {
content: "\e96c";}

.icon-upload-alt:before {
content: "\e96d";}

.icon-usb1:before {
content: "\e96e";}

.icon-user:before {
content: "\e970";}

.icon-volume-down:before {
content: "\e971";}

.icon-volume-off:before {
content: "\e972";}

.icon-volume-up:before {
content: "\e973";}

.icon-windows:before {
content: "\e975";}

.icon-youtube:before {
content: "\e976";}
