
// Bootstrap overrides: tooltip & popover

.t1-popup {
    cursor: pointer;
    outline: none; //

    &:hover {
        color: $colour-default-red;
    }

}

.t1-popup-close {
    cursor: pointer;
    margin-bottom: 1.0rem;
    text-align: right;
    &:hover {
        color: $colour-default-red;
    }
}

// Tooltip

.tooltip {
    font-size: $font-size-base;
    &.in {
        opacity: 1.0;
    }
}

.tooltip-inner {
    background: rgba(30, 42, 50, 0.9);
    border-radius: 3px;
    border: 0;
    color: #fff;
    padding: 10px;
}

.tooltip-arrow {

}

// Popover

.popover {
    background: rgba(30, 42, 50, 0.9);
    border-radius: 3px;
    border: 0;
    font-size: $font-size-base;
    color: #fff;
    padding: 10px;
    width: 100%;
}

.popover-arrow {
    left: 8% !important;
    // border-top-color: rgba(30, 42, 50, 0.9) !important;
    border-top-color: transparent;
    &:after {
        border-top-color: rgba(30, 42, 50, 0.9) !important;
    }
}

.popover-title {
    display: none;
}

.popover-content {
    padding: 0;
}
