
svg.pie {

    background: $colour-default-grey-8;
    border-radius: 50%;

    transform: rotate(-90deg);
    transform-origin: center center;

    // @include transform(rotate(-90deg));
    // @include transform-origin(center);

    & circle {
        fill: $colour-default-grey-8;
        stroke-width: 75px;
        stroke-dasharray: 0 157;
    }

    & circle.pie-value-1 {
        stroke: $colour-vi-red-1;
    }

    & circle.pie-value-2 {
        stroke: $colour-default-grey-9;
    }

    & circle.pie-value-3 {
        stroke: $colour-vi-cyan-2;
    }

    & circle.pie-value-4 {
        stroke: $colour-vi-green-1;
    }

    & circle.pie-value-5 {
        stroke: $colour-vi-cyan-1;
    }

    & circle.pie-value-6 {
        stroke: $colour-promo-2;
    }

    & circle.pie-value-7 {
        stroke: $colour-vi-green-2;
    }

    & circle.pie-value-8 {
        stroke: $colour-vi-orange-1;
    }

    & circle.pie-center {
        fill: #fff;
    }

}
