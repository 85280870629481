
.noUi-horizontal {
  height: 8px;
}

.noUi-horizontal .noUi-handle {
  width: 18px;
  height: 18px;
  left: -8px;
  top: -5px;
}
.noUi-vertical {
  width: 5px;
}
.noUi-vertical .noUi-handle {
  width: 18px;
  height: 18px;
  // täytyy säätää vielä, jos pystyslider:
  left: 0px;
  top: 0px;
}

.noUi-handle {
  border: 4px solid $colour-default-red;
  border-radius: 50%;
  background: $colour-default-red;
  cursor: default;
  box-shadow: none;
  outline-color: transparent;
  outline-style: none;
}

.noUi-target {
  background: #e3e5e6;
  border-radius: 4px;
  border: none;
  box-shadow: none;
}

.noUi-connect {
  background: #e3e5e6;
  border-radius: 4px;
  box-shadow: none;
}

// Disable handle stripes
.noUi-handle:before,
.noUi-handle:after {
  display: none;
}

.slider-labels {
	margin-top: 12px;
	font-size: 13px;
}

.slider-labels .right {
	float: right;
}