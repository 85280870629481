.cookie-box {

    background: $colour-default-grey-1;

    font-size: 13px;
    font-size: 1.3rem;
    color: #fff;

    opacity: 0;

    padding: 10px 0px;

    position: fixed;
        bottom: 0%;
        left: 0;

    width: 100%;

    z-index: 5;

    & .container {
        position: relative;
    }

    & .cookie-box-close {
        cursor: pointer;
        position: absolute;
            top: 0;
            right: 15px;
        &:hover {
            text-decoration: underline;
        }
    }

    & a {
        color: inherit;
        font-size: inherit;
    }

    & p {
        color: inherit;
        font-size: inherit;
        // margin-top: 1.0rem;
        margin-bottom: 1.0rem;
    }

    transform: translateY(100%);
    transition: all 0.4s ease;

    // @include transform(translateY(100%));
    // @include transition(all 0.4s ease);

    &.is-visible {
        opacity: 1;
        transform: translateY(0%);
        transition: all 0.4s ease;
        // @include transform(translateY(0%));
        // @include transition(all 0.4s ease);
    }

}