/* Toyota Display */

@font-face {
	font-family: 'ToyotaType-Regular-italic';
	src: url('../fonts/ToyotaType-Italic.woff2') format('woff2'),
			url('../fonts/ToyotaType-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'ToyotaType-Semibold';
	src: url('../fonts/ToyotaType-Semibold.woff2') format('woff2'),
			url('../fonts/ToyotaType-Semibold.woff') format('woff');
	font-weight: 600;

	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'ToyotaType-Light-italic';
	src: url('../fonts/ToyotaType-SemiboldIt.woff2') format('woff2'),
			url('../fonts/ToyotaType-SemiboldIt.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'ToyotaType-Light';
	src: url('../fonts/ToyotaType-Light.woff2') format('woff2'),
			url('../fonts/ToyotaType-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'ToyotaType-Book';
	src: url('../fonts/ToyotaType-Book.woff2') format('woff2'),
			url('../fonts/ToyotaType-Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'ToyotaType-Regular';
	src: url('../fonts/ToyotaType-Regular.woff2') format('woff2'),
			url('../fonts/ToyotaType-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'ToyotaType-Book-italic';
	src: url('../fonts/ToyotaType-BookIt.woff2') format('woff2'),
			url('../fonts/ToyotaType-BookIt.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'ToyotaType-Light-italic';
	src: url('../fonts/ToyotaType-LightIt.woff2') format('woff2'),
			url('../fonts/ToyotaType-LightIt.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

/*
@font-face {
	font-family: 'ToyotaType-Light';
	src: url('../fonts/ToyotaType-Light.otf') format('otf');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'ToyotaType-Light-italic';
	src: url('../fonts/ToyotaType-LightIt.otf') format('otf');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'ToyotaType-Regular';
	src: url('../fonts/ToyotaType-Regular.otf') format('otf');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'ToyotaType-Regular-italic';
	src: url('../fonts/ToyotaType-RegularIt.otf') format('otf');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'ToyotaType-Book';
	src: url('../fonts/ToyotaType-Book.otf') format('otf');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'ToyotaType-Book-italic';
	src: url('../fonts/ToyotaType-BookIt.otf') format('otf');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'ToyotaType-Semibold';
	src: url('../fonts/ToyotaType-Semibold.otf') format('otf');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'ToyotaType-Semibold-italic';
	src: url('../fonts/ToyotaType-SemiboldIt.otf') format('otf');
	font-weight: 600;
	font-style: normal;
}
*/

@include media-breakpoint-up(xs) {

	h1 {
		font-size: 34px;
		line-height: 40px;
		text-transform: uppercase;
		&.large-text {
			font-size: 44px;
			line-height:48px;
		}
	}

	h2 {
		font-size: 22px;
		line-height: 28px;
		font-family: $font-family-sans-regular;
		text-transform: uppercase;
	}

	h3 {
		font: 16px/24px $font-family-sans-semibold;
	}

	h4 {
		font: 16px/24px $font-family-sans-regular;
	}

	h5 {
		font: 16px/24px $font-family-sans-semibold;
	}

	.quote3 {
		font: 16px/24px $font-family-sans-serif;
	}

	.support-text, small {
		font: 13px/20px $font-family-sans-serif;
	}
}

@include media-breakpoint-up(sm) {

	h1 {
		font-size: 34px;
		line-height: 40px;
	}

	h2 {
		font-size: 22px;
		line-height: 28px;
	}

	h3 {
		font-size: 16px;
		line-height:24px;
	}

	h4 {
		font-size: 16px;
		line-height:24px;
	}

	h5 {
		font-size: 16px;
		line-height: 24px;
	}

	.quote3 {
		font: 16px/24px $font-family-sans-serif;
	}

	.support-text, small {
		font: 13px/1.23 $font-family-sans-serif;
	}
}

@include media-breakpoint-up(md) {

	h1 {
		font-size: 44px;
		line-height:48px;
		&.large-text {
			font-size: 68px;
			line-height:76px;
		}
	}

	h2 {
		font-size: 34px;
		line-height:40px;
	}

	h3 {
		font-size: 22px;
		line-height:28px;
	}

	h4 {
		font-size: 19px; 
		line-height:26px;
	}

	.quote3 {
		font: 16px/24px $font-family-sans-serif;
	}

	.support-text, small {
		font: 13px/18px $font-family-sans-serif;
	}
}

@include media-breakpoint-up(lg) {

	h1 {
		font-size: 44px;
		line-height:48px;
		&.large-text {
			font-size: 68px;
			line-height:76px;
		}
	}

	h2 {
		font-size: 34px;
		line-height:40px;
	}

}

.intro, .t1-introduction-text {
	font: 22px/28px $font-family-sans-serif;
}

p {
	font: 16px/24px $font-family-sans-serif;
}

h1, h2, h3, h4, h5 {
	&.book-font {
		font-family: $font-family-sans-serif;
		text-transform: none;
	}
}

b, strong {
	font-family: $font-family-sans-semibold;
	font-weight: normal;
}
em {
	font-family: $font-family-sans-italic;
}

.intro, .t1-introduction-text, .quote3, .support-text, small, h1, h2, h3, h4, h5, h6 {
	margin: 0;
}
code {
	font-size: 13px;
	line-height: 18px;
	color: $colour-default-black;
}