
//body { -webkit-font-smoothing: antialiased; }

/* Normalize override */
button, input, optgroup, select, textarea {
	//font-family: 'Toyota Text Regular', sans-serif;
}

// responsive images
img { max-width: 100%; height: auto; }
svg { max-height: 100%; }

.close { cursor: pointer; }

.link--unstyled {
    display: block;
    color: inherit;
    text-decoration: none;
}

// Navbar
.navbar {
	border-bottom: 1px solid #f0f0f0;
}

.brand-tag {
	background-image: url('../images/toyota-logo.svg');
	background-repeat: no-repeat;
	height: 56px;
  	width: 66px;
	background-position: center center;
	background-size:60px 39px;
}


/* Border, extend Bootstrap border colours */

.border-grey-light {
	border-color: $colour-default-grey-light;
}