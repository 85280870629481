// V2 colours ----------------------------

// Corporate colours
$colour-default-white:		    #ffffff;
$colour-default-black:		    #282830;
$colour-default-red:		    #ff0022;
$colour-default-red-hover:      #d90022;

// Greys
$colour-default-grey-dark:      #282830;
$colour-default-grey-paragraph: #595d60;
$colour-default-grey-medium-1:  #6a6b70;
$colour-default-grey-medium:    #adadb0;
$colour-default-grey-light:     #f0f0f0;
$colour-default-grey-light-hover:#cecfd0;
$colour-transparent-grey: rgba(40,40,48,0.05);
$colour-transparent-light-grey: rgba(240,240,240, 0.96);
$colour-transparent-medium-grey: rgba($colour-default-grey-medium, 0.65);


// Additional colours
$colour-article-deep-purple:    #673ab7;
$colour-article-indigo:         #3f51b5;
$colour-article-blue:           #2196f3;
$colour-article-cyan:           #00bcd4;
$colour-article-teal:           #009688;
$colour-article-green:          #4caf50;


// Generated classes ----------------------------

// text colours
.text-red { color: $colour-default-red; }
.text-black { color: $colour-default-black; }
.text-white { color: $colour-default-white; }
.text-grey-dark { color: $colour-default-grey-dark; }
.text-grey-paragraph { color: $colour-default-grey-paragraph; }
.text-deep-purple { color: $colour-article-deep-purple; }
.text-indigo { color: $colour-article-indigo; }
.text-blue { color: $colour-article-blue; }
.text-cyan { color: $colour-article-cyan; }
.text-teal { color: $colour-article-teal; }
.text-green { color: $colour-article-green; }


// background colours
.bg-red, .bg-red--solid { background-color: $colour-default-red; }
.bg-white, .bg-white--solid { background-color: $colour-default-white; }
.bg-black, .bg-black--solid { background-color: $colour-default-black; }

.bg-grey-dark, .bg-grey-dark--solid { background-color: $colour-default-grey-dark; }
.bg-grey-light, .bg-grey-light--solid { background-color: $colour-default-grey-light; }
.bg-grey-light-hover, .bg-grey-light-hover--solid { background-color: $colour-default-grey-light-hover; }
.bg-grey-paragraph, .bg-grey-paragraph--solid { background-color: $colour-default-grey-paragraph; }
.bg-grey-medium-1, .bg-grey-medium-1--solid { background-color: $colour-default-grey-medium-1; }
.bg-grey-medium, .bg-grey-medium--solid { background-color: $colour-default-grey-medium; }
.bg-grey-transparent, .bg-grey-transparent--solid { background-color: $colour-transparent-grey; }
.bg-grey-light-transparent, .bg-grey-light-transparent--solid { background-color: $colour-transparent-light-grey; }
.bg-grey-medium-transparent, .bg-grey-medium-transparent--solid { background-color: $colour-transparent-medium-grey; }

.bg-deep-purple, .bg-deep-purple--solid  { background-color: $colour-article-deep-purple; }
.bg-indigo, .bg-indigo--solid  { background-color: $colour-article-indigo; }
.bg-blue, .bg-blue--solid { background-color: $colour-article-blue; }
.bg-cyan, .bg-cyan--solid { background-color: $colour-article-cyan; }
.bg-teal, .bg-teal--solid { background-color: $colour-article-teal; }
.bg-green, .bg-green--solid { background-color: $colour-article-green; }




// V1 colours ----------------------------

$colour-default-grey-0: 	      #222222;
$colour-default-grey-1: 	      #1e2a32;
$colour-default-grey-2: 	      #6c7073;
$colour-default-grey-3: 	      #eff0f1;
$colour-default-grey-4: 	      #f4f4f5;
$colour-default-grey-5:         #E6E7E8;
$colour-default-grey-6:         #BDBFC0;
$colour-default-grey-7:         #888f93;
$colour-default-grey-8:         #d2d4d6;
$colour-default-grey-9:         #343f46;
$colour-default-grey-10:        #f5f5f6;
$colour-default-grey-11:        #D9D9D9;

$colour-default-select:		      $colour-default-grey-3;

// Promotion colours
$colour-promo-1: 	              #efc60a;
$colour-promo-2: 	              #ef9109;
$colour-promo-3:                #932788;

// Visual Identity colours
$colour-vi-red-1: 		          #d2001e;
$colour-vi-red-2: 		          #73000d;
$colour-vi-orange-1: 		        #f07f0a;
$colour-vi-orange-2: 		        #ef4123;
$colour-vi-yellow-1: 		        #f8ea00;
$colour-vi-yellow-2: 		        #b5751e;
$colour-vi-purple-1: 		        #7a1482;
$colour-vi-purple-2: 		        #420042;
$colour-vi-green-1: 		        #009b3c;
$colour-vi-green-2: 		        #1e4623;
$colour-vi-navy-1: 		          #004297;
$colour-vi-navy-2: 		          #0f0546;
$colour-vi-coolgrey5-1: 	      #969c97;
$colour-vi-coolgrey5-2: 	      $colour-default-grey-1;
$colour-vi-coolgrey11-1: 	      $colour-default-grey-1;
$colour-vi-coolgrey11-2: 	      #000000;
$colour-vi-cyan-1: 		          #00a0f0;
$colour-vi-cyan-2: 		          #005faf;

// Button styles
$colour-btn-grey-1: 		        #e8e9ea;
$colour-btn-grey-2: 		        $colour-default-white;
$colour-btn-dark-1: 		        #303a40;
$colour-btn-dark-2: 		        #454e55;
$colour-btn-blue-1: 	  	      #005faf;
$colour-btn-blue-2: 	  	      #00a0f0;
$colour-btn-red-1: 		          $colour-vi-red-2;
$colour-btn-red-2: 		          #d1001e;
$colour-btn-yellow-1: 		      #ef9109;
$colour-btn-yellow-2: 		      #efc60a;

// Other
$colour-navbar-border:          #eff0f1;
$colour-navbar-active:          #27323a;
$colour-navbar-menu-border:     #354046;
$colour-paragraph-border:       #e2e2e3;

$colour-shadow-box-dark: rgba(2, 2, 2, .15);
$colour-shadow-text-dark: rgba(2, 3, 3, .2);

//please no rgba in the variables unless shadows
$colour-table-header:           #ebeced;
$colour-table-row-odd: $colour-default-grey-10;
$colour-table-promo-row-odd:    #fefcf3;
$colour-table-promo-border:     #f1e4d4;

$colour-bg-navy:                #1c2830;
$colour-bg-grey-textfield:      #525B61;
$colour-bg-grey-light:          #e9eaec;
$colour-bg-beige:               #f0f2f2;

$colour-bg-dark-blue-separator: #303a40;
$colour-share-grey-separator:   #e3e4e5;

$colour-second-level-menu:      #fdfdfd;
$colour-collapsible-table-row:  #DDDDDD;
$colour-ecm-gray-icon:          #999999;
$colour-cta-grey-1:             #848b90;
$colour-twitter-cyan:           #19C1F7;
$colour-coockies-xtra-back:     #f2f2f3;
$colour-dark-grey:              #333333;
$colour-highlighted-star:       #8f9496;


.bg-lightgrey--solid { background-color: $colour-default-grey-5; }
.bg-darkgrey--solid { background-color: $colour-default-grey-1; }
    .bg-darkgrey--solid a { color: $colour-default-white; }
    .bg-darkgrey--solid a:hover, .bg-darkgrey--solid a:active { color: $colour-default-grey-5; }
.bg-red--solid { background-color: $colour-default-red; }
.bg-vi-cyan-1--solid { background-color: $colour-vi-cyan-1; }

.bg-basic--gradient { background-image: linear-gradient(to bottom, $colour-btn-grey-2 0%, $colour-btn-grey-1 100%); }
.bg-purple--radial { background-image: radial-gradient( $colour-vi-purple-1, $colour-vi-purple-2 ); }
.bg-promo--radial { background-image: radial-gradient( $colour-promo-1, $colour-promo-2); }

.text-default-grey-1 { color: $colour-default-grey-1; }
.text-colour-promo-2 { color: $colour-promo-2; }