
.t1-brand-tag-container {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
}
@media (min-width: 1140px) {
  .t1-brand-tag-container {
    width: 1140px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.t1-brand-tag--md {
  background-image: url('../images/toyota-logo.svg');
  background-repeat: no-repeat;
  margin-top: 0;
  position: absolute;
  right: 20px;
  top: 0;
  //box-shadow: 0 0 5px rgba(0,0,0,.4);
/*vanhan brändtagin muutos pelkäksi logoksi */
  height: 56px;
  width: 66px;
  background-position: center center;
  background-size: 60px 39px;
};

.t1-brand-tag--xs {
  background-image: url('../images/toyota-logo.svg');
  background-repeat: no-repeat;
  margin-top: 0;
  position: absolute;
  right: 20px;
  top: 0;
  height: 56px;
  width: 66px;
  background-position: center center;
  background-size: 60px 39px;
};

.logo-link {
  display:inline-block;
  width:100%;
  height:100%;
};