.t1-spinner {
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-radius: 50%;
    border-top: 1.1em solid rgba(30, 42, 50, 0.2);
    border-right: 1.1em solid rgba(30, 42, 50, 0.2);
    border-bottom: 1.1em solid rgba(30, 42, 50, 0.2);
    border-left: 1.1em solid rgba(30, 42, 50, 0.6);
    transform: translateZ(0);
    animation: spinner 1.1s infinite linear;
    width: 10em;
    height: 10em;
}

.t1-spinner-small {
    border-top: 0.5em solid rgba(30, 42, 50, 0.2);
    border-right: 0.5em solid rgba(30, 42, 50, 0.2);
    border-bottom: 0.5em solid rgba(30, 42, 50, 0.2);
    border-left: 0.5em solid rgba(30, 42, 50, 0.6);
    width: 3em;
    height: 3em;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
