/* https://www.filamentgroup.com/lab/select-css.html */

/* Firefox: remove dotted outline */
@-moz-document url-prefix() {
  select {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
  }
}

select {
  display: block;
  font-size: 16px;
  font-weight: 500;
  height: 46px;
  padding: 7px 6px 9px 16px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #f0f0f0;
  //box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .25rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='1792' height='1792' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z' fill='%23e50000'/%3E%3C/svg%3E"),
    linear-gradient(to bottom, #f0f0f0 0%,#f0f0f0 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}
select.bg-white {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='1792' height='1792' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z' fill='%23e50000'/%3E%3C/svg%3E"),
  linear-gradient(to bottom, #fff 0%,#fff 100%);
}
select::-ms-expand {
  display: none;
}
select:hover {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='1792' height='1792' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z' fill='%23e50000'/%3E%3C/svg%3E"),
    linear-gradient(to bottom, #e4e4e4 0%,#e4e4e4 100%);
  border-color: #e4e4e4;
}
select:focus {
  border-color: #fff;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='1792' height='1792' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1683 1331l-166 165q-19 19-45 19t-45-19l-531-531-531 531q-19 19-45 19t-45-19l-166-165q-19-19-19-45.5t19-45.5l742-741q19-19 45-19t45 19l742 741q19 19 19 45.5t-19 45.5z' fill='%23e50000'/%3E%3C/svg%3E"),
    linear-gradient(to bottom, #fff  0%,#fff 100%);
  border-color: #e4e4e4;
	box-shadow: 0 2px 10px 0 rgba(0,0,0,.15);
  color: #202020;
  outline: none;
}
select option {
  font-weight:normal;
}