
// Bootstrap overrides

.btn {
  min-width: 160px;
  padding: 0 30px;
  line-height: 45px;
  border-radius: 2em;
  font-family: $font-family-sans-serif;
  font-size: 15px;
  color: $colour-default-black;
  background: $colour-default-grey-light;
  text-overflow: ellipsis;
  max-width:100%;
  transition: all 0.2s ease-in;
  @include hover {
    cursor: pointer;
    background: $colour-default-grey-light-hover;
  }
  &.active, &:active, &:focus, &:active:focus, &.active:focus {
    cursor: pointer;
    background: darken($colour-default-grey-light, 5%);
  }
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .65;
  }
  &-flexwidth {
    min-width:0;
  }
  &.iconlink {
    text-align: left;
    padding-left:0;
    .icon {
      width: auto;
      margin-left: 22px;
      margin-right:10px;
    }
  }
  &.btn-chevron {
    padding-right: 57px;
    text-align: left;
    position:relative;
    .icon {
      position: absolute;
      bottom: 0;
      right: 22px;
      line-height: 40px;
      margin: 0;
      color: $colour-default-red;
      &:before {
        font-size: 20px;
      }
    }
  }
}

.btn-white {
  color: $colour-default-black;
  background: $colour-default-white;
  border: 1px solid $colour-default-white;
  @include hover {
    color: $colour-default-black;
    background: $colour-default-white;
  	border: 1px solid darken( $colour-default-grey-light, 10%);
    box-shadow: inset 0px 0px 0px 1px darken( $colour-default-grey-light, 10%);
  }
  &.active, &:active, &:focus, &:active:focus, &.active:focus {
    color: $colour-default-black;
    background:$colour-default-white;
  }
}

.btn-t1-primary,
.btn-primary {
  color: $colour-default-white;
  background: $colour-default-red;
  @include hover {
    background:$colour-default-red-hover;
    color: $colour-default-white;
  }
  &.active, &:active, &:focus, &:active:focus, &.active:focus {
    background:$colour-default-red-hover;
    color: $colour-default-white;
  }
}

.btn-t1-secondary,
.btn-secondary {
  color: $colour-default-red;
  background: $colour-default-white;
  border: 1px solid $colour-default-red;
  @include hover {
    background: $colour-default-white;
    color: $colour-default-red;
    box-shadow: inset 0px 0px 0px 1px $colour-default-red-hover;
  }
  &.active, &:active, &:focus, &:active:focus, &.active:focus {
    background: $colour-default-white;
    box-shadow: inset 0px 0px 0px 1px $colour-default-red-hover;
  }
}

.btn-t1-function,
.btn-function {
  color: $colour-default-white;
  background: $colour-default-black;
  @include hover {
    background: rgba(0,0,0,0.9);
    color: darken($colour-default-grey-light, 5%);
  }
  &.active, &:active, &:focus, &:active:focus, &.active:focus {
    background: rgba(0,0,0,0.9);
    color: darken($colour-default-grey-light, 5%);
  }
}

a.btn {
	text-decoration: none;
}

.btn-sm {
  padding: 0 12px;
  font-size: 12px;
  line-height: 34px;
  height: 35px;
}

.btn-round {
  width: 2.5em;
  height:2.5em;
  border-radius: 50%;
  padding: 0;
  min-width: auto;
  text-align: center;
  border: none;
  cursor: pointer;
}

@include media-breakpoint-up(xs) {
  .btn {
    //width: 100%;
  }
  .btn + .btn {
    margin: 13px 0 0 0;
  }
}

@include media-breakpoint-up(sm) {
  .btn {
    width: auto;
  }
  .btn + .btn {
    margin: 0 0 0 13px;
  }
}


// icon spacing
.btn .fa {
  margin-left: -8px;
  padding-right: 10px;
}

.btn.sm .fa {
  margin-left: -2px;
  padding-right: 10px;
}

/*
.btn-t1-basic {
color: $colour-default-grey-2;
border: 1px solid #d8d9da;
@include gradient-vertical($colour-btn-grey-2, $colour-btn-grey-1);
@include hover {
text-decoration: underline;
color: $colour-default-grey-2;
border: 1px solid $colour-vi-coolgrey5-1;
background: $colour-btn-grey-1;
}
&.active, &:active, &:focus, &:active:focus, &.active:focus {
background: $colour-btn-grey-1;
box-shadow:0 1px 1px #fff, inset 0 0 8px rgba(30,42,50,.6);
}
}

.btn-t1-cta {
color: $colour-default-white;
border: 1px solid $colour-btn-red-1;
@include gradient-vertical($colour-btn-red-2, $colour-btn-red-1);
@include hover {
text-decoration: underline;
border: 1px solid $colour-btn-red-1;
background: $colour-btn-red-1;
}
&.active, &:active, &:focus, &:active:focus, &.active:focus {
background: $colour-btn-red-1;
box-shadow:0 1px 1px #fff, inset 0 0 8px rgba(30,42,50,.6);
}
}

.btn-t1-form {
color: $colour-default-white;
border: 1px solid $colour-btn-dark-1;
@include gradient-vertical($colour-btn-dark-2, $colour-btn-dark-1);
@include hover {
text-decoration: underline;
border: 1px solid $colour-btn-dark-1;
background: $colour-btn-dark-1;
}
&.active, &:active, &:focus, &:active:focus, &.active:focus {
background: $colour-btn-dark-1;
box-shadow:0 1px 1px #fff, inset 0 0 8px rgba(30,42,50,.6);
}
}

.btn-t1-promotion {
color: $colour-default-white;
border: 1px solid $colour-btn-yellow-1;
@include gradient-vertical($colour-btn-yellow-2, $colour-btn-yellow-1);
@include hover {
text-decoration: underline;
border: 1px solid $colour-btn-yellow-1;
background: $colour-btn-yellow-1;
}
&:active, &:focus, &:active:focus, &.active:focus {
background: $colour-btn-yellow-1;
box-shadow:0 1px 1px #fff, inset 0 0 8px rgba(30,42,50,.6);
}
}

.btn-t1-hybrid {
color: $colour-default-white;
border: 1px solid $colour-btn-blue-1;
@include gradient-vertical($colour-btn-blue-2, $colour-btn-blue-1);
@include hover {
text-decoration: underline;
border: 1px solid $colour-btn-blue-1;
background: $colour-btn-blue-1;
}
&:active, &:focus, &:active:focus, &.active:focus {
background: $colour-btn-blue-1;
box-shadow:0 1px 1px #fff, inset 0 0 8px rgba(30,42,50,.6);
}
}

.btn-t1-jousto {
color: $colour-default-white;
border: 1px solid $colour-vi-purple-2;
@include gradient-vertical($colour-vi-purple-1, $colour-vi-purple-2);
@include hover {
text-decoration: underline;
border: 1px solid $colour-vi-purple-2;
background: $colour-vi-purple-2;
}
&:active, &:focus, &:active:focus, &.active:focus {
background: $colour-vi-purple-2;
box-shadow:0 1px 1px #fff, inset 0 0 8px rgba(30,42,50,.6);
}
}

// Plain button without .btn class
button  {
background-color: transparent;
@include hover {
text-decoration: underline;
border: 1px solid $colour-vi-coolgrey5-1;
background-color: rgba(255,255,255,.05);
cursor: pointer;
}
&:active, &:focus, &:active:focus, &.active:focus {
background-color: rgba(255,255,255,.05);
box-shadow:0 1px 1px #fff, inset 0 0 8px rgba(30,42,50,.6);
}
}
*/


// Remove focus glow from buttons
.btn:focus, .btn:active:focus, .btn.active:focus {
  outline-color: transparent;
  outline-style: none;
}

.btn-block {
  position: relative;
  text-align: left;
  width: 100%;
}

// Add chevron to block button
.btn-block--chevron:after {
  content: "\f054";
  position: absolute;
  right: 1em;
  font-family: FontAwesome;
}

/*
@include media-breakpoint-down(sm) {
.btn {
position: relative;
width: 100%;
text-align: left;
}
.btn:after {
content: "\f054";
position: absolute;
right: 1em;
font-family: FontAwesome;
}
}
*/
button > .radio { margin-bottom: 0; }