.modelrange {
  display: block;
  color: $colour-default-black;
  font-size: 13px;
  text-decoration: none;
  padding-bottom: 0.5em;
  margin-bottom: 17px;
  border-bottom: 1px solid #a9aaac;
}
.modelrange:hover {
  color: $colour-default-black;
  text-decoration: none;
  margin-bottom: 16px;
  border-bottom: 2px solid $colour-default-red;
}
.modelrange img { margin-bottom: -2.5rem; }
.modelrange .price-from {
  color: #6c7073;
}

.hybrid-available {
  padding: 0 4px;
  font-size: 11px;
  font-family: 'OpenSans-Bold';
  color: $colour-default-white;
  background: #00a0f0;
  border-radius: 2px;
}

.modelrange.selected {
  margin-bottom: 16px;
  border-bottom: 2px solid $colour-default-red;
}
