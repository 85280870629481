.tag-link {
	display: inline-block;
	width:100%;
	height: 100%;
}

a.tab-link {
	padding: 0 20px 10px 0;
	margin: 0 20px 0 0;
	color: $colour-default-black;
	text-decoration: none;
	border-bottom: 3px solid #f0f0f0;
	transition: all 0.25s ease-in;
	&:hover {
		border-bottom: 3px solid #f02;
	}
	&.active {
		border-bottom: 3px solid #f02;
		font-family: $font-family-sans-semibold;
	}
}