// Accordion

.panel-title a {
  position: relative;
  display: block;
  padding: 10px 0 10px 50px;
  background: $colour-default-white;
  text-decoration: none;
}
.panel-title a:hover,
.panel-title a:focus {
  color: $colour-default-black;
  text-decoration: none;
}

.panel-title a[aria-expanded="true"] {
  background-color: transparent;
}

.panel-title a[aria-expanded="true"]:before {
  content: "\f077";
  position: absolute;
  left: 10px;
  font-family: FontAwesome;
}

.panel-title a[aria-expanded="false"]:before {
  content: "\f078";
  position: absolute;
  left: 10px;
  font-family: FontAwesome;
}

.panel-collapse {
 /* margin-bottom: 1.5em; */
 /* padding: 1em 1.5em; */
  background: $colour-default-white;
  box-shadow: 0 0 5px rgba(0,0,0,.4);
}



/* New accordion, structure from Bootstrap */

.accordion a {
	text-decoration: none;
}