.navbar {
	padding: 0;
	min-height: 70px;
	border-bottom: 1px solid #f0f0f0;
	transition: .25s;
}

.navbar .brand-tag {
	//margin-top: .45em;
	background-image: url('../images/toyota-logo.svg');
	background-repeat: no-repeat;
	height: 39px;
	width: 60px;
}

.navbar-toggler {
	border: none;
}

.navbar-collapse {
	transition: .25s;
}

.navbar-collapse.show {
	background-color: #f0f0f0;
}

.nav-link {
	display: inline-block;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	line-height: 43px;
	font-size: 14px;
	text-decoration: none;
	transition: .25s;
}

.navbar-collapse.show .nav-link {
	display: block;
	border-top: none;
	line-height: 1.5;
}

.nav-link:hover {
	background: #e50000;
	color: #fff !important;
}

.navbar-collapse.show .nav-link:hover {
	background: none;
	color: #e50000 !important;
}

.nav-link.active {
	border-top: 5px solid #e50000;
}

.navbar-collapse.show .nav-link.active {
	border-top: none;
}

/* spacing override */
.navbar-expand-lg .navbar-nav .nav-link {
	padding-right: 1.5em;
	padding-left: 1.5em;
}

/*Secondary Nav Bar*/
.secondary-navbar{
	z-index: 900;
	margin-top: 2em;
}

.nav-link-secondary {
	display: inline-block;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	line-height: 43px;
	font-size: 14px;
	text-decoration: none;
	transition: .25s;
}

.nav-link-secondary:hover {
	background: transparent;
	color: #e50000 !important;
}

.nav-link-secondary.active{
	border-top: 5px solid transparent;
  color:#e50000;
  font-weight: bold;
}

.secondary-nav-positioner{
	margin-top: .45em;
	height: 56px;
	width: 66px;
}